import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Home from "../contents/Home"
import About from "../contents/About"
import Work from "../contents/Work"
import Contact from "../contents/Contact"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="山本印刷" />
      <IndexPageWrapper>
        <section>
          <Home />
          <About />
          <Work />
          <Contact />
        </section>
      </IndexPageWrapper>
    </Layout>
  )
}

export default IndexPage

const IndexPageWrapper = styled.div``
