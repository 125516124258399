import React from "react"
import styled from "styled-components"

const YamamotoCreativeLogoSvg = () => {
  return (
    <YamamotoCreativeLogoSvgWrapper
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <title>山本印刷ロゴ</title>
      <g>
        <path d="M432.4,509.4c-22.7,0-41.1-18.4-41.1-41.1V146.4c0-22.7,18.4-41.1,41.1-41.1c22.7,0,41.1,18.4,41.1,41.1v321.9C473.5,491,455.1,509.4,432.4,509.4z" />
        <path d="M257.7,509.4c-22.7,0-41.1-18.4-41.1-41.1V43.7c0-22.7,18.4-41.1,41.1-41.1s41.1,18.4,41.1,41.1v424.7C298.8,491,280.4,509.4,257.7,509.4z" />
        <path d="M79.6,509.4c-22.7,0-41.1-18.4-41.1-41.1V146.4c0-22.7,18.4-41.1,41.1-41.1s41.1,18.4,41.1,41.1v321.9C120.7,491,102.3,509.4,79.6,509.4z" />
      </g>
    </YamamotoCreativeLogoSvgWrapper>
  )
}

export default YamamotoCreativeLogoSvg

const YamamotoCreativeLogoSvgWrapper = styled.svg`
  width: 15px;
  height: 15px;

  path {
    fill: white;
    transition: fill 0.3s ease-in;
  }

  &:hover {
    path {
      fill: #66fcf1;
    }
  }
`
