import React from "react"
import styled from "styled-components"
import { useScrollAnimation } from "../hooks"
import { media } from "../utilities"
import ContentContainer from "../components-for-contents/ContentContainer"
import BrowserSvg from "../components-for-contents/BrowserSvg"

const About = () => {
  const [ref, showAnimation] = useScrollAnimation()

  return (
    <AboutWrapper id="about" showAnimation={showAnimation}>
      <ContentContainer className="about-container">
        <div className="about-text">
          <p ref={ref}>
            山本印刷は、三重県松阪市を拠点として長年の間、印刷サービスをご提供しております。
            その経験をもとに、お客様に合わせたオリジナルデザインのホームページとアプリケーション制作を、最新の技術にてご支援させて頂いております。
            また、海外向けのホームページ制作も英語にて行なっています。
          </p>
        </div>
        <div className="about-svg">
          <BrowserSvg />
        </div>
      </ContentContainer>
    </AboutWrapper>
  )
}

export default About

const AboutWrapper = styled.div`
  .about-container {
    display: block;

    /* greater than 768px (medium) */
    ${media.md} {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 550px;
      padding-top: 7.25rem;
    }

    .about-text {
      transition: opacity 0.7s ease, transform 0.7s ease; // ToDo
      opacity: ${props => (props.showAnimation ? 1 : 0)};
      transform: ${props =>
        props.showAnimation ? "translateY(0)" : "translateY(1.45rem)"};

      width: 100%;

      /* greater than 768px (medium) */
      ${media.md} {
        width: 65%;
        padding-right: 1.45rem;
      }

      p {
        margin-bottom: 0;
      }
    }

    .about-svg {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      margin-top: 4.35rem;

      svg {
        width: 250px;
        height: 324px;
      }

      /* greater than 768px (medium) */
      ${media.md} {
        justify-content: flex-end;

        width: 35%;

        svg {
          width: 270px;
          height: 350px;
        }
      }
    }
  }
`
